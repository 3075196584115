:root {
  --first: #ff1b6b;
  --second: #45caff;
  --white: rgba(255, 255, 255, 0.888);
  --black: rgb(49, 48, 48);
}

* {
  margin: 0;
  padding: 0;
  
}
body {
  background: linear-gradient(0.25turn, #ff1b6b, #45caff);
  color: rgba(255, 255, 255, 0.888);
}

a {
  color: #45caff;
  text-decoration: none;
  font-weight: bold;
}

.appContainer {
  display: grid;
  grid-template-areas: 
  "topButton"
  "App"
  "printButton";
}

button, .pdfButton {
  border: none;
  text-decoration: none;
  padding: 14px 28px;
  width: 200px;
  /* margin: 0 auto 20px auto; */
  border-radius: 0px;
  font-size: 12pt;
  background-color: rgba(255, 255, 255, 0.888);
  color:rgb(49, 48, 48);
  box-shadow: #45caff 2px 2px;
  left: 0;
  top: 0;
  transition: all 0.4s;
  letter-spacing: 0.1em;
}

button:hover, .pdfButton:hover {
  position: relative;
  left: -15px;
  top: -10px;
  box-shadow: #45caff -15px -5px;
  z-index: 3;
  font-size: 16pt;
}

.pdfButton:hover {
  left: 15px;
  top: 10px;
}

button:active, .pdfButton:active {
  left: 3px;
  top: 3px;
  box-shadow: #45caff 2px 2px;
}

.pdfButton {
  font-weight: normal;
  
}

.printButton {
  grid-area: printButton;
}

@media screen and (max-width: 500px) {
  .printButton:hover, .pdfButton:hover {
    left: 0;
    top: 0;
  }
}

.topButton {
  grid-area: topButton;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: -50px;
  opacity: 0.4;
  box-shadow: #45caff 5px -5px;
}

.topButton:hover {
  opacity: 1;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.888);
  color: rgb(49, 48, 48);
  border-left: none;
  border-top: none;
  position: relative;
  top: 15px;
  left: -15px;
  box-shadow: #45caff 10px -10px;
}

.printButton:active {
  left: 3px;
  top: 3px;
  box-shadow: #45caff 2px 2px;
}

@media screen and (max-width: 500px) {
  .printButton, .pdfButton {
    display: none;
  }
}


.App {
  grid-area: App;
  display: grid;
  max-width: 80%;
  grid-template-areas: 
  "header header header"
  "contact main main"
  "summary main main"
  "sidebar main main"
  "sidebar languages hobbies"
  "ShowSearchTerm ShowSearchTerm ShowSearchTerm";

  margin: auto auto;
  grid-template-columns: 35% 33% 33%;
  padding-bottom: 30px;
  border-bottom: 5px dotted rgba(255, 255, 255, 0.888);
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .App {
    max-width: 1440px;
  }
  
}

@media screen and (max-width: 768px) {
  .App {
    max-width: 100%;
  }
  
}

@media screen and (max-width: 900px) {
  .App {
    grid-template-areas: 
    "header"
    "contact"
    "summary"
    "main"
    "sidebar"
    "ShowSearchTerm";
  margin: 10px auto;
  max-width: 100%;
  grid-template-columns: 100%}
  
}

/* Header */

header {
  grid-area: header;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 5px dotted rgba(255, 255, 255, 0.888);
  margin-bottom: 30px;
}
.profilePicture, .profilePictureFlipped {
  width: 200px;
  border-radius: 50%;
  box-shadow: rgb(49, 48, 48) -5px 5px;
  top: 0px;
  left: 0px;
  transition: box-shadow 0.5s, top 0.5s, left 0.5s;
  cursor: pointer;
}

.profilePicture:hover, .profilePictureFlipped:hover {
  position: relative;
  top: -15px;
  left: 15px;
  box-shadow: rgb(49, 48, 48) -15px 15px;
  border: 2px solid white;
}

.profilePicture:active, .profilePictureFlipped:active {
  position: relative;
  top: 0px;
  left: 0px;
  box-shadow: none;
  transform: rotate(90deg);
}

.titleHolder {
  justify-items: flex-end;
  align-items: center;
  padding: 7px;
  border-left: 5px solid #ff1b6b;
  border-top: 5px solid #ff1b6b;
  box-shadow: #45caff 5px 5px;
  background-color: rgb(49, 48, 48);
}

.titleHolderFlipped {
  justify-items: flex-end;
  align-items: center;
  padding: 7px;
  border-left: 5px solid #45caff;
  border-top: 5px solid #45caff;
  box-shadow: #ff1b6b 5px 5px;
  background-color: rgb(49, 48, 48);
}

.headerName {
  font-size: 26px;
}

.headerTitle {
  font-size: 22px;
  text-align: right;
  font-style: italic;
}

@media  screen and (max-width: 500px) {
  .headerName {
    font-size: 24px;
  }
  
  .headerTitle {
    font-size: 20px;
  }
}

.offlineLink {
  display:none;
}

/* Search */
.searchField {
  padding: 8px;
  border-radius: 15px;
  border: 1px solid gray;
  margin: 20px;
  max-height: 50px;
  color: rgb(49, 48, 48);
}

@media screen and (max-width: 500px) {
  form {
    min-width: 100%;
    display: flex;
  justify-content: center;
  }
  .searchField {
    min-width: 80%;
  }
}

/* Widget */
.Widget {
  margin: 2px;
  left: 0px;
  top: 0px;
  background-color: rgb(49, 48, 48);
  box-shadow: #ff1b6b 5px 5px;
  transition: left 1s, top 1s;
  letter-spacing: 0.1em;
}

.WidgetFlipped {
  margin: 2px;
  left: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.888);
  color:rgb(49, 48, 48);
  box-shadow: #45caff 5px 5px;
  transition: left 1s, top 1s;
  letter-spacing: 0.1em;
}

.Widget:hover {
  position: relative;
  left: -15px;
  top: -10px;
  box-shadow: #45caff 5px 5px;
  /* box-shadow: #ff1b6b -5px -5px; */
  border-left: #ff1b6b solid 5px;
  border-top: #ff1b6b solid 5px;
  z-index: 3;
}

.WidgetFlipped:hover {
  position: relative;
  left: 15px;
  top: 10px;
  box-shadow: #ff1b6b 5px 5px;
  /* box-shadow: #ff1b6b -5px -5px; */
  border-left: #45caff solid 5px;
  border-top: #45caff  solid 5px;
  z-index: 3; 
}

@media screen and (max-width: 900px) {
  .Widget:hover, .WidgetFlipped:hover {
    left: 0px;
    top: 0px;
  }
}

.widgetTitle {
  /* insert widget Title styles here */
  color: #ff1b6b;
  padding-bottom: 7px;
  border-bottom: 2px solid #45caff;
  margin-bottom: 7px;
}

.widgetTitleFlipped {
  /* insert widget Title styles here */
  color: #45caff;
  padding-bottom: 7px;
  border-bottom: 2px solid #ff1b6b;
  margin-bottom: 7px;
}

.contact {
  grid-area: contact;
  
}

.contactKey {
  font-weight: 500;
}

.summary {
  grid-area: summary;
  /* font-style:italic; */
}

.summary p {
  padding-bottom: 8px;
}


/* SideBar */

.SideBar {
  grid-area: sidebar;
}

.summary, .contact, .keySkills, .techSkills, .mktSkills, .addSkills, .languages, .hobbies, .ShowSearchTerm{
  list-style: none;
  padding: 30px;

  }

/* Content */

.Content {
  grid-area: main;
}

/* JobsList */

.jobsTable {
  width: 100%;
}

.Experience, .Education, .certifications {
  padding: 30px;
  color:rgb(49, 48, 48);
  background-color: rgba(255, 255, 255, 0.888);

}

.ExperienceFlipped, .EducationFlipped, .certificationsFlipped {
  padding: 30px;
  color:rgba(255, 255, 255, 0.888);
  background-color: rgb(49, 48, 48);
}

.jobTitle {
  font-weight: bold;
  width: 70%;
}

.jobCompany {
  font-style: italic;
  font-size: 0.8em;
}

.jobPeriod {
  width: 30%;
}


@media screen and (max-width: 500px) {
  .jobCompany {
    font-size: 0.8em;
    padding-right: 30px;
  }
}
.jobDescription {
  padding-bottom: 10px;

}

td {
  vertical-align: top;
}

#certItem {
  list-style: disc;
}

.ShowSearchTerm {
  grid-area: ShowSearchTerm;
  opacity: 60%;
}



/* Animations */

@media (prefers-reduced-motion: no-preference) {
  .titleHolderFlipped, .profilePictureFlipped{
    animation: App-logo-spin  1s 2 alternate;
  }

  .WidgetFlipped {
    animation: widgetFloat 10s infinite linear;
  }

  @media screen and (max-width: 500px) {
    .WidgetFlipped {
      animation: widgetFloat 1s 1 alternate;
    }
    
  }

  .Widget {
    animation: widgetFloat 1s 1 alternate;
  }

  .titleHolderFlipped {
    animation: spin-in-place 20s infinite alternate;
  }

  .titleHolder, .profilePicture, .Content{
    animation: appSlide 1s 1 linear;
  }

  .profilePicture {
    animation: borderFlow 1s infinite;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    margin-top: 100%;
  }
}

@keyframes spin-in-place {
  from {
    transform: rotate(0deg);

  }
  to {
    transform: rotate(360deg);

  }
}

@keyframes widgetFloat {
  from {

    margin-top: 0%; 
    margin-left: 0%;

  }
  33% {
    margin-top: -3%;
    margin-left: -3%;
  }
  66% {
    margin-top: 3%;
    margin-left: 3%;
  }
  to {
    margin-top: 0%; 
    margin-left: 0%;
  }
}

@keyframes appSlide {
  from {
    margin-left: 0%;
  }
  25% {
    margin-left: -100%;
  }
  50% {
    margin-top: 40%;
    margin-left: 100%;
    background-color: #45caff;
  }
  75% {
    margin-top: -40%;
    margin-left: 25%;
  }
  to {
    margin-left: 0%;
  }
}

@keyframes borderFlow {
  from {
    border: 2px solid #45caff;
  }
  20% {
    border-left: 2px solid #ff1b6b
  }

  40% {
    border-bottom: 2px solid #ff1b6b
  }

  60% {
    border-right: 2px solid #ff1b6b
  }

  80% {
    border-top: 2px solid #ff1b6b;
  }

  to {
    border: 2px solid #45caff;
  }
}